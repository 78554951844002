import { Icon } from '@chakra-ui/react';
import {
  MdAutoAwesome,
  MdAutoGraph,
  MdExtension,
  MdHistory,
  MdManageAccounts,
  MdNotificationsNone,
  MdOutlineEmail,
  MdOutlineExtension,
  MdOutlinePayment,
  MdOutlinePolicy,
  MdOutlinePrivacyTip,
} from 'react-icons/md';
import { ImCogs } from "react-icons/im";
import { TbTargetArrow } from "react-icons/tb";
// Admin Imports
import MainDashboard from 'views/admin/default';

import Chat from 'views/client/ask-question/chat';
import Pricing from 'views/client/pricing';
import SearchChat from 'views/client/searchChat';
import EmailReview from 'views/client/solutions/email-review/page';
import MarketingReview from 'views/client/solutions/marketing-review/page';
import OrganizationComplaintsManagement from 'views/client/solutions/organization-complaints-management/page';
import PolicyGeneator from 'views/client/solutions/policy-generator/page';
import ProductReview from 'views/client/solutions/product-review/page';
import RegulatoryReportingAssistant from 'views/client/solutions/regulatory-reporting-assistant/page';
import RegulatoryChangeReview from 'views/client/solutions/regulatory/page';
import WholeBodyTesting from 'views/client/solutions/whole-body-testing/page';
import PrivacyPolicy from 'views/shared/privacy';
import ProfileOverview from 'views/shared/profile';
import { FeatureRoute } from './models/routes';
import NotificationCount from './components/notificationCount/notificationCount';

const routes: FeatureRoute[] = [
  {
    name: 'Ask a Question',
    layout: '/compliance',
    path: '/chat',
    icon: (
      <Icon as={MdAutoAwesome} width="20px" height="20px" color="inherit" />
    ),
    component: Chat,
    secondary: true,
    plan: "Basic",
    showOnSidebar: true,
  },
  {
    name: 'Solutions',
    layout: '/compliance',
    path: '/solutions',
    icon: <Icon as={MdOutlineExtension} width="20px" height="20px" color="inherit"/>,
    component: MainDashboard,
    collapse: false,
    rightElement: true,
    plan: "Basic",
    showOnSidebar: true,
  },
  {
    name: 'Subscription Plans',
    layout: '/compliance',
    path: '/pricing',
    icon: (
      <Icon as={MdOutlinePayment} width="20px" height="20px" color="inherit" />
    ),
    component: Pricing,
    collapse: false,
    rightElement: true,
    plan: "Basic",
    showOnSidebar: true,
  },
  {
    name: 'Institution Profile',
    layout: '/compliance',
    path: '/profile-overview',
    icon: (
      <Icon as={MdManageAccounts} width="20px" height="20px" color="inherit" />
    ),
    component: ProfileOverview,
    collapse: false,
    rightElement: true,
    plan: "Basic",
    showOnSidebar: true,
  },
  {
    name: 'Chat History',
    layout: '/compliance',
    path: '/search-chat',
    icon: <Icon as={MdHistory} width="20px" height="20px" color="inherit" />,
    component: SearchChat,
    collapse: false,
    rightElement: true,
    plan: "Basic",
    showOnSidebar: false,
  },
  {
    name: 'Policy Generator',
    layout: '/compliance',
    path: '/policy-generator',
    icon: (
      <Icon as={MdOutlinePayment} width="20px" height="20px" color="inherit" />
    ),
    component: PolicyGeneator,
    collapse: false,
    rightElement: true,
    plan: "Premium",
    showOnSidebar: false,
  },
  {
    name: 'Policy Review',
    layout: '/compliance',
    path: '/policy-review',
    icon: (
      <Icon as={MdOutlinePolicy} width="20px" height="20px" color="inherit" />
    ),
    component: PolicyGeneator,
    collapse: false,
    rightElement: true,
    plan: "Premium",
    showOnSidebar: false,
  },
  {
    name: 'Marketing Review',
    layout: '/compliance',
    path: '/marketing-review',
    icon: (
      <Icon as={MdAutoGraph} width="20px" height="20px" color="inherit" />
    ),
    component: MarketingReview,
    collapse: false,
    rightElement: true,
    plan: "Basic",
    showOnSidebar: false,
  },
  {
    name: 'Email Review',
    layout: '/compliance',
    path: '/email-review',
    icon: (
      <Icon as={MdOutlineEmail} width="20px" height="20px" color="inherit" />
    ),
    component: EmailReview,
    collapse: false,
    rightElement: true,
    plan: "Basic",
    showOnSidebar: false,
  },
  {
    name: 'Product Review',
    layout: '/compliance',
    path: '/product-review',
    icon: (
      <Icon as={ImCogs } width="20px" height="20px" color="inherit" style={{ transform: 'scaleX(-1) scaleY(-1)' }}/>
    ),
    component: ProductReview,
    collapse: false,
    rightElement: true,
    plan: "",
    showOnSidebar: false,
  },
  {
    name: 'Regulatory Change Review',
    layout: '/compliance',
    path: '/regulatory-change-review',
    icon: (
      <>
        <Icon as={MdNotificationsNone} width="20px" height="20px" color="inherit" />
      </>
    ),
    component: RegulatoryChangeReview,
    collapse: false,
    rightElement: true,
    plan: "Premium",
    showOnSidebar: true,
  },
  {
    name: '100% Document Testing',
    layout: '/compliance',
    path: '/whole-body-testing',
    icon: (
      <Icon as={TbTargetArrow} width="20px" height="20px" color="inherit" />
    ),
    component: WholeBodyTesting,
    collapse: false,
    rightElement: true,
    plan: "Enterprise",
    showOnSidebar: false,
  },
  {
    name: 'Organization Complaints Management',
    layout: '/compliance',
    path: '/organization-complaints-management',
    icon: (
      <Icon as={TbTargetArrow} width="20px" height="20px" color="inherit" />
    ),
    component: OrganizationComplaintsManagement,
    collapse: false,
    rightElement: true,
    plan: "Enterprise",
    showOnSidebar: false,
  },
  {
    name: 'Regulatory Reporting Assistant',
    layout: '/compliance',
    path: '/regulatory-reporting-assistant',
    icon: (
      <Icon as={TbTargetArrow} width="20px" height="20px" color="inherit" />
    ),
    component: RegulatoryReportingAssistant,
    collapse: false,
    rightElement: true,
    plan: "Enterprise",
    showOnSidebar: false,
  },
  {
    name: 'Privacy Policy',
    layout: '/compliance',
    path: '/privacy',
    icon: (
      <Icon as={MdOutlinePrivacyTip} width="20px" height="20px" color="inherit" />
    ),
    component: PrivacyPolicy,
    collapse: false,
    rightElement: true,
    plan: "Basic",
    showOnSidebar: false,
  },
];

export default routes;
